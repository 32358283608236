<template>
  <footer class="footer px-4">
    <div class="row align-items-center justify-content-lg-between">
      <div class="col-lg-6">
        <div class="copyright text-center text-lg-left text-muted">
          © {{year}} Fair Contract
        </div>
      </div>
      <div class="col-lg-6">
        <ul class="nav nav-footer justify-content-center justify-content-lg-end">
         
          <!-- <li class="nav-item">
            <a href="https://www.creative-tim.com/presentation" class="nav-link" target="_blank">О компании</a>
          </li> -->
          <!-- <li class="nav-item">
            <a href="http://blog.creative-tim.com" class="nav-link" target="_blank">Новости</a>
          </li>
          <li class="nav-item">
            <router-link to="/agreement/" tag="a" class="nav-link">Пользовательское соглашение</router-link>
          </li> -->
        </ul>
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
