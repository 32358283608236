<template>
  <ul class="navbar-nav align-items-center d-none d-md-flex">
    <li class="nav-item" v-if="!user.email">
      <base-button
        type="primary"
        @click="$store.dispatch('system/showModal',{show:true})"
        icon="ni ni-key-25"
      >Войти</base-button>
    </li>
    <li class="nav-item dropdown" v-else>
      <base-dropdown class="nav-link pr-0">
        <div class="media align-items-center" slot="title">
          <span class="avatar avatar-sm rounded-circle">
            <img alt="Image placeholder" src="img/theme/user.png" />
          </span>
          <div class="media-body ml-2 d-none d-lg-block">
            <span class="mb-0 text-sm font-weight-bold">{{user.email}}</span>
          </div>
        </div>

        <template>
          <div class="dropdown-header noti-title">
            <h6 class="text-overflow m-0">Персональное меню</h6>
          </div>
          <router-link to="/profile" class="dropdown-item">
            <i class="ni ni-single-02"></i>
            <span>Мой профиль</span>
          </router-link>
          <router-link to="/balance" class="dropdown-item">
            <i class="ni ni-money-coins"></i>
            <span>Платежи</span>
          </router-link>
          <!-- <router-link to="/history" class="dropdown-item">
            <i class="ni ni-calendar-grid-58"></i>
            <span>История действий</span>
          </router-link>-->
          <router-link to="/support" class="dropdown-item">
            <i class="ni ni-support-16"></i>
            <span>Поддержка</span>
          </router-link>
          <div class="dropdown-divider"></div>
          <a href="#" @click="$store.dispatch('user/signUserOut')" class="dropdown-item">
            <i class="ni ni-user-run"></i>
            <span>Выход</span>
          </a>
        </template>
      </base-dropdown>
    </li>
  </ul>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters({
      user: "user/getCurrentUser"
    })
  }
};
</script>