/* eslint-disable */
import Vue from 'vue'
import Router from 'vue-router'
import DashboardLayout from '@/layout/DashboardLayout'
import AuthLayout from '@/layout/AuthLayout'
Vue.use(Router)

export default new Router({
  linkExactActiveClass: 'active',
  routes: [
    {
      path: '/',
      redirect: '/contract/new',
      component: DashboardLayout,
      children: [
        // {
        //   path: '/',
        //   name: 'Создать договор через Fair Contract',
        //   // route level code-splitting
        //   // this generates a separate chunk (about.[hash].js) for this route
        //   // which is lazy-loaded when the route is visited.
        //   component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
        // },
        // {
        //   path: '/agreement',
        //   name:  'Пользовательское соглашение',
        //   component: () => import(/* webpackChunkName: "static" */ './views/Agreement.vue')
        // },
        {
          path: '/contracts/:status?',
          name: 'Контракты',
          component: () => import(/* webpackChunkName: "demo" */ './views/Contracts.vue')
        },
        {
          path: '/contract/new',
          name: 'Новый документ',
          component: () => import(/* webpackChunkName: "demo" */ './views/PickContract.vue')
        },
        {
          path: '/contract/:id',
          name: 'Договор',
          component: () => import(/* webpackChunkName: "demo" */ './views/Dashboard.vue')
        },
        {
          path: '/profile',
          name:  'Профиль',
          component: () => import(/* webpackChunkName: "demo" */ './views/UserProfile.vue')
        },
        {
          path: '/balance',
          name:  'Платежи',
          component: () => import(/* webpackChunkName: "demo" */ './views/Payments.vue')
        },
         {
          path: '/signup',
          name:  'Регистрация',
          component: () => import(/* webpackChunkName: "demo" */ './views/Signup.vue')
        },
        {
          path: '/tables',
          name: 'tables',
          component: () => import(/* webpackChunkName: "demo" */ './views/Tables.vue')
        },
        {
          path: '/admin',
          name: 'Административная панель',
          component: () => import(/* webpackChunkName: "admin" */ './views/AdminPanel.vue')
        }
      ]
    },
    {
      path: '/',
      redirect: 'login',
      component: AuthLayout,
      children: [
        {
          path: '/login',
          name: 'login',
          component: () => import(/* webpackChunkName: "demo" */ './views/Login.vue')
        },
        {
          path: '/register',
          name: 'register',
          component: () => import(/* webpackChunkName: "demo" */ './views/Register.vue')
        }
      ]
    },
     { 
    path: '*', 
    redirect: '/' 
  }
  ]
})
