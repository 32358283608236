import axios from 'axios'
import {functions,auth} from '@/firebaseConfig.js'
 
const invite = functions.httpsCallable('sendWelcomeEmail');
const verify = functions.httpsCallable('verifyUser');
const attachContracts = functions.httpsCallable('attachContracts');
const reAgreement = functions.httpsCallable('reAgreement');
const replaceToken = functions.httpsCallable('replaceToken');
const getInfo = functions.httpsCallable('getInfo');
const yandexAPI = 'https://cors-anywhere.herokuapp.com/https://payment.yandex.net/api/v3/payments'

//axios.defaults.withCredentials = true
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=utf-8';
axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';

export default {
  admin: {
    verifyUser:(data) => verify(data)
  },
  contract:{
    reagreement:(contract) => reAgreement(contract)
  },
  user :{
    replaceToken: (tkn) => replaceToken(tkn),
    resetPassword: (email) => auth.sendPasswordResetEmail(email),
    attach : () => attachContracts(),
    invite:(data) => invite(data),
    suggestion: (suggest) => getInfo(suggest)},
  payment: {
    get: (paymentId) => axios.get(yandexAPI + '/' + paymentId, {
      auth: {
        username: '558871',
        password: 'live_4kTSXVMPRqsLuUTRw3SDRUA261yZhq_rueunDitMYe4'
      }
    }).catch(err => Promise.reject(err)),
    add: (payment) => axios.post(yandexAPI, payment, {
      auth: {
        username: '558871',
        password: 'live_4kTSXVMPRqsLuUTRw3SDRUA261yZhq_rueunDitMYe4'
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        'Content-Type': ' application/json',
        'Idempotence-Key': payment.id
      }
    }).catch(err => Promise.reject(err)),
    edit: (data) => axios.put(yandexAPI, data),
    remove: (paymentId) => axios.delete(yandexAPI, {
      params: {
        ID: paymentId
      }
    })
  }

}
