
import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/auth'
import 'firebase/functions' 
import 'firebase/messaging'
import 'firebase/storage'

// firebase init goes here
const config = {
  apiKey: "AIzaSyDwcMA-7oYl2s-6hXWWx0SniOs7w8idgmg",
  authDomain: "fair-contract.firebaseapp.com",
  databaseURL: "https://fair-contract.firebaseio.com",
  projectId: "fair-contract",
  storageBucket: "fair-contract.appspot.com",
  messagingSenderId: "183016404185",
  appId: "1:183016404185:web:8485153a7097e1ed"
};
firebase.initializeApp(config)
// firebase.functions().useEmulator('localhost','5001') 
// firebase utils
const db = firebase.firestore()
const functions = firebase.functions()
const auth = firebase.auth()
const storage = firebase.storage();
const authConsts = firebase.auth;
const messaging = firebase.messaging.isSupported() ? firebase.messaging() : null;
const currentUser = auth.currentUser 
const deleteField = () => firebase.firestore.FieldValue.delete()
const currentTimestamp = firebase.firestore.Timestamp.fromDate(new Date()) ;
//currentUser.info =  db.doc(`users/${currentUser.uid}`);
// date issue fix according to firebase 

if (messaging) 
  messaging.getToken({vapidKey:"BI8s2UeN7ESuwUISjA3G7rUqB_fWpsglsL-FU8U_3bRS-E3rRnpbwiMePneGsH3-Nyi1LFOxa871Fqfch2uKEUE"});
// firebase collections
const usersCollection = db.collection('users')
const paymentsCollection = db.collection('payments')
const contractsCol = db.collection('contracts')

// var connectedRef = db.doc(".info/connected");
// connectedRef.get().then(function(snap) {
//   if (snap.val() === true) {
//     alert("connected");
//   } else {
//     alert("not connected");
//   }
// }).catch(err => alert(err.code));
export {
  db,
  auth,
  authConsts,
  currentUser,
  functions,
  storage,
  contractsCol,
  usersCollection,
  paymentsCollection,
  deleteField,
  messaging,
  currentTimestamp
}
