<template>
  <div class="wrapper vld-parent" :class="{ 'nav-open': $sidebar.showSidebar }">
    <loading
      :active.sync="isLoading"
      loader="bars"
      :is-full-page="true"
      :can-cancel="false"
      :z-index="99999"
    ></loading>
    <side-bar
      :background-color="sidebarBackground"
      short-title="FC"
      title="Fair Contract"
      v-intro="'Здесь будут находиться ваши будущие договоры'"
      v-intro-step="2"
      v-intro-position="'right'"
    >
      <template slot="links">
        <sidebar-item
          :link="{
            class: ['btn', 'btn-success'],
            name: 'Создать договор',
            icon: 'ni ni-paper-diploma',
            path: '/'
          }"
        />

        <sidebar-item :link="{name: 'Черновики', class:[], icon: '', path: '/contracts/draft'}" />
        <sidebar-item
          :link="{name: 'На согласовании', class:[],icon: '', path: '/contracts/agreement'}"
        />
        <sidebar-item
          :link="{name: 'На подписании', class:[],icon: '', path: '/contracts/signing'}"
        />
        <sidebar-item :link="{name: 'Подписано', class:[],icon: '', path: '/contracts/signed'}" />
        <sidebar-item :link="{name: 'Все', class:[],icon: '', path: '/contracts/'}" />
        <sidebar-item :link="{name: 'Корзина', class:[],icon: '', path: '/contracts/removed'}" />
        <sidebar-item :link="{name: 'Архив', class:[],icon: '', path: '/contracts/archive'}" />
      </template>
    </side-bar>
    <div class="main-content" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>

      <div @click="toggleSidebar">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
        <content-footer v-if="!$route.meta.hideFooter"></content-footer>
      </div>
    </div>
    <login-modal></login-modal>
  </div>
</template>
<script>
import LoginModal from "@/views/Login/index.vue";
import DashboardNavbar from "./DashboardNavbar.vue";

import ContentFooter from "./ContentFooter.vue";
import { mapGetters } from "vuex";
import { FadeTransition } from "vue2-transitions";
import Loading from "vue-loading-overlay";
// Import stylesheet
import "vue-loading-overlay/dist/vue-loading.css";
export default {
  components: {
    DashboardNavbar,
    LoginModal,
    ContentFooter,
    FadeTransition,
    Loading
  },
  data() {
    return {
      sidebarBackground: "vue" //vue|blue|orange|green|red|primary
    };
  },

  watch: {
    loginModal: function(newVal) {
      this.showModal = newVal;
    },
    info(val) {
      if (val) {
        this.$notify({
          type: "info",
          title: val
        });
        this.$store.dispatch("system/setInformation", null);
      }
    },
    error(val) {
      if (val) {
        this.$notify({
          type: "danger",
          title: val
        });
        this.$store.dispatch("system/setError", null);
      }
    }
  },
  computed: {
    ...mapGetters({
      error: "system/error",
      // loginScreen:"system/login",
      info: "system/info",
      isLoading: "system/loading"
    })
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    }
  }
};
</script>
