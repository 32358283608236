//store/index.js
import Vue from 'vue'
import Vuex from 'vuex'

import { vuexfireMutations, firestoreAction } from 'vuexfire'
import {usersCollection, contractsCol} from '@/firebaseConfig'
import user from './modules/user'
// import operations from './modules/operations'
import system from './modules/system'


//import createLogger from '../../../src/plugins/logger'

Vue.use(Vuex)



//const debug = process.env.NODE_ENV !== 'production'

export const store =  new Vuex.Store({
  state:{
    contracts : [],
    statuses:{
      draft:0,
      agreement:1,
      signing: 2,
      signed:3
    },
    payments:[],
    notes: [],
    history: [],
    contract: null 
  },
  getters: {
    statuses : state => state.statuses,
    contract:  state => state.contract,
    notes : state => state.notes,
    history : state => state.history,
    payments: state => state.payments,
    contracts: state => state.contracts,
  },
  mutations: vuexfireMutations,
  actions: {
    bindPaymentsRef:firestoreAction((store,userID) =>{

      return store.bindFirestoreRef('payments', usersCollection.doc(userID).collection('payments'))
    }),
    unbindPaymentsRef:firestoreAction(({unbindFirestoreRef}) =>{
      return unbindFirestoreRef('payments')
    }),
    bindHistoryRef: firestoreAction(({ bindFirestoreRef},cID) =>{
      return bindFirestoreRef('history', contractsCol.doc(cID).collection('history').orderBy('updated'))
    }),
    bindCurrentRef: firestoreAction((store,cID) =>{
      store.dispatch('bindNotesRef',cID);
      store.dispatch('bindHistoryRef',cID);
      return store.bindFirestoreRef('contract', contractsCol.doc(cID))
    }),
    unbindCurrentRef: firestoreAction(({unbindFirestoreRef}) =>{
      unbindFirestoreRef('notes')
      return unbindFirestoreRef('contract')
    }),
    bindNotesRef : firestoreAction(({ bindFirestoreRef},cID) =>{
      return bindFirestoreRef('notes', contractsCol.doc(cID).collection('notes'))
    }),
    bindTodosRef: firestoreAction((context,status) => {
      // context contains all original properties like commit, state, etc
      // and adds `bindFirestoreRef` and `unbindFirestoreRef`
      // we return the promise returned by `bindFirestoreRef` that will
      // resolve once data is ready
      let contracts =  contractsCol;
      let anonymUser = localStorage.getItem('anonymUser') || '';
      if(context.state.user.currentUser)
        contracts =  contracts.where('contributors','array-contains',context.state.user.currentUser.uid)
      else  
        contracts =  contracts.where('contributors','array-contains',anonymUser)
      
      console.log(contracts)
      if(status)
        return context.bindFirestoreRef('contracts', contracts.where('status','==',status))
      else
        return context.bindFirestoreRef('contracts', contracts)
    })
  },
  modules: {
    user,
    system
    // operations
  },
 // strict: debug,
 // plugins: debug ? [createLogger()] : []
})