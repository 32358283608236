import Vue from "vue";
import BootstrapVue from 'bootstrap-vue';
import App from "./App.vue";
import "vue-loading-overlay/dist/vue-loading.css";
// Init plugin
import {messaging, auth, db} from "@/firebaseConfig.js";
import { firestorePlugin } from "vuefire";
import moment from "moment";
import router from "./router";
//import "./registerServiceWorker";
import { store } from "./store/";
import ArgonDashboard from "./plugins/dashboard-plugin";
import VueIntro from 'vue-introjs';


Vue.use(BootstrapVue)
import 'bootstrap-vue/dist/bootstrap-vue.css'
// import VueFormWizard from 'vue-form-wizard'
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// Vue.use(VueFormWizard)
// //Vue.config.productionTip = false;
router.beforeEach((to, from, next) => {
  //const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  //const requiresAuth = to.matched.some(x => x.meta.requiresAuth);
  // If a route with a title was found, set the document (page) title to that value.
  //if (nearestWithTitle)
  document.title = to.name + " | Fair-contract"; 
  // if (requiresAuth && currentUser && verified) {
  // }
  // if (requiresAuth && currentUser && verified) {
  //   console.log(1);
  //   next();
  // } else if (requiresAuth && (!currentUser || !verified)) {
  //   console.log(2);
  //   next("/login");
  // } else
  
   auth.onAuthStateChanged(() => {
      // if (to.name === "Регистрация" && user) {
      //   let redirect = to.query.dogID;
      //   if(redirect)
      //     next(`/contract/${redirect}`)
      //   else
      //     next(`/`);
      // } else {
        next();
      //}
});
});
// const serialize = (snapshot) => {
//   // documentSnapshot.data() DOES NOT contain the `id` of the document. By
//   // default, Vuefire adds it as a non enumerable property named id.
//   // This allows to easily create copies when updating documents, as using
//   // the spread operator won't copy it
//   return Object.defineProperty(doc.data(), 'id', { value: doc.id })
// }
function requestNotificationsPermissions() {
  Notification
    .requestPermission()
    .then( permission => {
      //console.log(permission)
      if (permission === 'granted') {
        saveMessagingDeviceToken();
      } else {
        console.log('Unable to get permission to notify.');
      }
     
    })
} 
function saveMessagingDeviceToken() {
  messaging
    .getToken()
    .then(function(currentToken) {
      if (currentToken) {
        console.log("Got FCM device token:", currentToken);
        // Saving the Device Token to the datastore.
        db.collection("fcmTokens").doc(currentToken).set({ uid: auth.currentUser.uid });
      } else {
        // Need to request permissions to show notifications.
        requestNotificationsPermissions();
      }
    })
    .catch(function(error) {
      console.log('An error occurred while retrieving token. ', error);

    });
}

Vue.filter("formatDate", function(value) {
  if (!value) return "Нет даты";
  value = moment(value.toDate());
  return value.format("DD.MM.YYYY");
});

Vue.use(firestorePlugin);
Vue.use(ArgonDashboard);
Vue.use(VueIntro);
import 'intro.js/introjs.css';
new Vue({
  render: h => h(App),
  router,
  store,
  data: {},
  created() {
    auth.onAuthStateChanged(user => {
      if (user) {
        auth.useDeviceLanguage();
        //console.log(user);
        store
          .dispatch("user/fetchUserProfile", user)
          .then(() => {
            if (messaging != null) {
              requestNotificationsPermissions();
              messaging.onMessage(function(payload) {
                console.log('Message received. ', payload);
              });
            }
           if (router.currentRoute.query.dogID)
              router.push(`/contract/${router.currentRoute.query.dogID}`);
         })
          .catch(err => {
            console.error(err);

             if(err.code==='unavailable')
               store.commit('system/setError', 'Возникли проблемы с соединением. Конфликт с Вашей антивирусной программой. Пожалуйста, обратитесь в чат поддержки и мы быстро решим вопрос!')
          });
      }
    });
  }
}).$mount("#app");
