<template>
  <base-nav class="navbar-top navbar-dark" id="navbar-main" :show-toggle-button="false" expand>
    <form class="navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto">
      <div class="form-group mb-0" v-if="verified === false && user.email">
        <badge
          tag="a"
          href="#/profile"
          type="danger"
          v-b-tooltip.hover
          title="Неверифицированные пользователи не могут оплачивать договор"
        >Учётная запись не подтверждена</badge>

        <!-- <base-input placeholder="Поиск"
                            class="input-group-alternative"
                            alternative=""
                            addon-right-icon="fas fa-search">
        </base-input>-->
      </div>
    </form>
    <login-box></login-box>
  </base-nav>
</template>
<script>
import LoginBox from "@/components/LoginBox.vue";
export default {
  components: {
    LoginBox
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchQuery: ""
    };
  },
  computed: {
    user() {
      return this.$store.getters["user/getUserInfo"];
    },
    verified() {
      return this.user ? this.user.status === "verified" : true;
    }
  },

  mounted() {
    this.$sidebar.displaySidebar(false);
  },
  methods: {
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    }
  }
};
</script>
<style >
.main-content .navbar-top {
  z-index: 100;
  position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1;
    background-color: transparent;
    padding-left: 0!important;
    padding-right: 0!important
}
.navbar-vertical .navbar-brand {
  z-index: 10;
  padding: 0px;
  margin: 0px 0;
    position: relative;
    color: #fff;text-align: center;

    text-shadow: 0 1px 0 #000;
    text-transform: uppercase;
    font-weight: 900;
}
</style>