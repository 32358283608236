<template>
  <modal
    :show.sync="showModal"
    @close="$store.dispatch('system/showModal',{show:false})"
    body-classes="py-2 px-0"
    :show-close="true"
    modal-classes="modal-dialog-centered modal-sm"
  >
    <button
      type="button"
      class="close"
      v-if="showModal"
      @click="showModal = false"
      data-dismiss="modal"
      aria-label="Close"
    >×</button>
    <card type="secondary" shadow body-classes="px-lg-5 py-lg-3" class="border-0">
      <template v-if="loginModal.message">
        <h4
          class="text-center"
        >Для продолжения работы с договором, войдите или зарегистрируйтесь на платформе</h4>
        <hr class="mt-1 mb-2" />
      </template>
      <template v-if="loginScreen === 'login'">
        <div class="text-muted text-center mb-3">
          <h3>Войти на платформу</h3>
        </div>
        <form @submit="sendReset" v-if="resetPasswordForm">
          <base-input
            alternative
            class="mb-3"
            v-model="loginForm.email"
            placeholder="Почта куда направить сброс пароля"
            addon-left-icon="ni ni-email-83"
          ></base-input>
          <base-button type="primary" size="lg" block class="mt-4" @click="sendReset">Отправить сброс</base-button>
        </form>
        <form role="form" @submit="logIn" v-else>
          <base-input
            alternative
            class="mb-3"
            v-model="loginForm.email"
            placeholder="Почта"
            addon-left-icon="ni ni-email-83"
          ></base-input>
          <base-input
            class="mb-1"
            v-model="loginForm.pass"
            type="password"
            aria-autocomplete="list"
            placeholder="Пароль"
            addon-left-icon="ni ni-lock-circle-open"
          ></base-input>
          <base-checkbox size="sm">Запомнить меня</base-checkbox>
          <div class="text-center">
            <base-button type="primary" size="lg" block class="mt-4" @click="logIn" >Войти</base-button>

            <base-button outline type="secondary" @click="resetPasswordForm=true" size="sm" class="mt-1 mb-1">Забыли пароль?</base-button>
          </div>
          <hr class="mb-3 mt-2" />
          <div class="text-center mb-2">
            <small>Нет учётной записи?</small>
          </div>
          <base-button type="info" size="lg" block @click="loginScreen='signup'">Зарегистрироваться</base-button>
        </form>
      </template>
      <template v-if="loginScreen === 'signup'">
        <form role="signup" @submit="signUp">
          <div class="text-muted text-center mb-3">
            <h3>Регистрация на Fair Contract</h3>
          </div>
          <base-input
            alternative
            :class="['mb-2',{disabled: $route.query.invitemail}]"
            :disabled="$route.query.invitemail"
            v-model="signupForm.email"
            required
            placeholder="Почта"
            addon-left-icon="ni ni-email-83"
          ></base-input>
          <base-input
            type="password"
            required
            class="mb-2"
            placeholder="Пароль"
            v-model="signupForm.pass"
            addon-left-icon="ni ni-lock-circle-open"
          ></base-input>
          <div class="mb-2">
            <autocomplete
              ref="autocomplete"
              :search="search"
              :get-result-value="formattedDisplay"
              placeholder="Название компании/ИНН/ОГРН"
              aria-label="Название компании/ИНН/ОГРН"
              base-class="form-control"
              @submit="addDistributionGroup"
              auto-select
            ></autocomplete>
          </div>
          <base-input
            type="text"
            class="mb-2"
            required
            v-show="fillManual"
            placeholder="Наименование компании"
            v-model="signupForm.company.name"
            addon-left-icon="ni ni-badge"
          ></base-input>
          <base-input
            class="mb-2"
            type="text"
            v-show="fillManual"
            required
            v-model="signupForm.company.inn"
            placeholder="ИНН"
            addon-left-icon="ni ni-single-copy-04"
          ></base-input>

          <base-input
            type="text"
            class="mb-2"
            v-show="fillManual"
            required
            placeholder="ОГРН"
            v-model="signupForm.company.ogrn"
            addon-left-icon="ni ni-single-copy-04"
          ></base-input>
          <base-input
            type="text"
            required
            class="mb-2"
            v-show="fillManual"
            placeholder="Расчетный счет*"
            v-model="signupForm.company.rss"
            addon-left-icon="ni ni-credit-card"
          ></base-input>
          <base-input
            type="text"
            class="mb-2"
            v-show="fillManual"
            required
            placeholder="ФИО Ген. директора"
            v-model="signupForm.company.fio"
            addon-left-icon="ni ni-money-coins"
          ></base-input>
          <base-input
            type="text"
            class="mb-2"
            placeholder="БИК"
            v-show="fillManual"
            required
            v-model="signupForm.company.bank_bic"
            addon-left-icon="ni ni-money-coins"
          ></base-input>
          <div class="text-center">
            <a href="#" @click="fillManual = true" v-show="!fillManual">
              <badge tag="span" type="info" block>Ввести остальные реквизиты вручную</badge>
            </a>
            <div class="text-left mt-1" v-show="fillManual">
              <base-checkbox size="sm" v-model="agreement">
                Согласен с условиями
                <router-link to="/agreement/">пользовательского соглашения</router-link>
              </base-checkbox>
            </div>
            <base-button
              :type="!getRegister  ? 'secondary': 'info'"
              size="lg"
              block
              v-b-tooltip.hover
              :title="!getRegister  ? 'Заполните все поля со звёздочкой' : ''"
              @click="signUp"
              class="mt-3"
            >Зарегистрироваться</base-button>
          </div>
        </form>
        <hr class="my-4" />
        <base-button type="primary" size="lg" block @click="loginScreen='login'">Войти на платформу</base-button>
      </template>
    </card>
  </modal>
</template>
<script>
//import axios from "axios";
import api from "@/api";
import { debounce } from "lodash";
import { mapGetters } from "vuex";
import Autocomplete from "@trevoreyre/autocomplete-vue";
export default {
  components: {
    Autocomplete
  },
  computed: {
    ...mapGetters({
      loginModal: "system/isLoginModal"
    }),
    getRegister() {
      if (
        this.signupForm.email &&
        this.signupForm.pass &&
        this.signupForm.company.name &&
        this.signupForm.company.inn &&
        this.agreement &&
        this.signupForm.company.ogrn
      )
        return true;
      else return false;
    }
  },
  data() {
    return {
      agreement: false,
      resetPasswordForm:false,
      loginScreen: "login",
      group: "",
      loginForm: {
        email: null,
        pass: null
      },
      signupForm: {
        email: "",
        pass: "",
        admin: false,
        status: "unverified",
        company: {}
      },
      searchTimeout: 0,
      showModal: false,
      fillManual: false,
      sidebarBackground: "vue" //vue|blue|orange|green|red|primary
    };
  },
  methods: {
    search: debounce(
      async input => {
        // this.searchTimeout = setTimeout(async () => {

        if (input.length < 3) {
          return [];
        } else {
          let suggestions = await api.user.suggestion(input);
          return suggestions.data.suggestions;
        }
      },
      600,
      { leading: true, maxWait: 2000, trailing: false }
    ),
    formattedDisplay(result) {
      return `${result.value} (ИНН ${result.data.inn})`;
    },
    sendReset(){
      this.$store.dispatch('user/sendResetPassword',this.loginForm.email)
      this.resetPasswordForm=false;
    },
    addDistributionGroup(selectedObj) {
      this.signupForm.company = Object.assign({}, this.signupForm.company, {
        name: selectedObj.value,
        inn: selectedObj.data.inn,
        ogrn: selectedObj.data.ogrn,
        fio: selectedObj.data.management.name
      });
      //console.log(group)
      this.fillManual = true;
      // access the autocomplete component methods from the parent
      //this.$refs.autocomplete.clear();
    },
    async logIn() {
      this.loginForm.attach = this.loginModal.message;
      await this.$store.dispatch("user/signUserIn", this.loginForm);
    },
    async signUp() {
      this.signupForm.attach = this.loginModal.message;
      if (!this.getRegister) return false;
      try {
        const signingUp = await this.$store.dispatch(
          "user/signUserUp",
          this.signupForm
        );
        if (this.$route.query.dogID && signingUp)
          this.$router.push(`/contract/${this.$route.query.dogID}`);
      } catch (err) {
        console.log(err);
      }
    }
  },
  mounted() {
    // axios.get('http://localhost:5000/fair-contract/us-central1/getInfo?word=54500').then((data)=>{
    //   console.log(data);
    // })

    if (this.$route.query.invitemail) {
      this.loginScreen = "signup";
      this.showModal = true;
      this.signupForm.email = this.$route.query.invitemail;
    }

    if (
      this.$route.query.dogID &&
      this.$store.getters["user/getCurrentUser"].email
    )
      this.$router.push(`/contract/${this.$route.query.dogID}`);
  },
  watch: {
    loginModal: function(newVal) {
      this.showModal = newVal.show;
    }
  }
};
</script>
<style lang="scss">
.modal-body {
  .close {
    position: absolute;
    cursor: pointer;
    right: 15px;
    top: 15px;
    z-index: 99;
  }
  .custom-control-label::before,
  .custom-control-label::after {
    left: -2rem;
  }
  .custom-control {
    padding-left: 2rem;
  }
  .form-control input {
    width: 100%;
    border: none;
    outline: none;
  }
  .input-group.disabled {
    .input-group-text {
      background: #e9ecef;
    }
  }
}
</style>
