import {auth,authConsts,usersCollection,deleteField, storage} from "@/firebaseConfig.js";
import { vuexfireMutations, firestoreAction } from 'vuexfire'
import api from '@/api'
import lang from "@/firebaseLang.js";  
auth.languageCode = "ru";
const state = {
  currentUser: {uid: localStorage.getItem('anonymUser')},
  userProfile: {
    
    company:{}
  }
}; 
 
const getters = {
  getCurrentUser: state => state.currentUser,
  getUserInfo: state => state.userProfile,
};
const reauthenticate = currentPassword => {
  var user = auth.currentUser;
  var cred = authConsts.EmailAuthProvider.credential(user.email, currentPassword);
  return user.reauthenticateWithCredential(cred);
};
const actions = {
  bindUserProfile: firestoreAction((store,userID) =>{

    return store.bindFirestoreRef('userProfile', usersCollection.doc(userID))
  }),
  inviteCooperator({ commit,dispatch }, inviteObj) {
      commit("system/setLoading", true, { root: true });
      return api.user.invite(inviteObj).then(res=>{
        commit("system/setLoading", false, { root: true})
        dispatch("system/setInformation", res.data, { root: true });
        Promise.resolve('ok');
      }).catch(err =>{
        commit("system/setLoading", false, { root: true });
        dispatch("system/setError", err, { root: true });
        Promise.reject();
      })
  },
  sendResetPassword({ commit }, email) {
    commit("system/setLoading", true, { root: true });
    return api.user.resetPassword(email).then( ()=>{
      commit("system/setLoading", false, { root: true})
      commit("system/setInfo", "Проверьте почту, пароль отправлен", { root: true });
      Promise.resolve('ok');
    }).catch(err =>{
      commit("system/setLoading", false, { root: true });
      console.log(err)
      commit("system/setError", err, { root: true });
      Promise.reject();
    })
  },
  
  async sendToVerification({commit}, url){
     commit("system/setLoading", true, { root: true });
    try{
     await usersCollection.doc(state.currentUser.uid).update({
            'status' : 'requested',
            'attachment': url
        });
      commit('setAwaitingStatus',url)  
      commit("system/setInfo", 'Ваши данные отправлены. Ожидайте подтверждения', { root: true });
    }
    catch(err){
      commit("system/setError", err, { root: true });
    }
     commit("system/setLoading", false, { root: true });
  },
   async removeAwaiting({commit},){
     commit("system/setLoading", true, { root: true });
    try{
      await storage.refFromURL(state.userProfile.attachment).delete()

      await usersCollection.doc(state.currentUser.uid).update({
            'status' : 'unverified',
            'attachment': deleteField()
        });
      commit('setUnverifiedStatus')
      commit("system/setInfo", 'Документ удалён', { root: true });
    }
    catch(err){
      console.log(err)
      commit("system/setError", err, { root: true });
    }
     commit("system/setLoading", false, { root: true });
  },
  async verifyUser({commit}, user){
    commit("system/setLoading", true, { root: true });
    let response = await api.admin.verifyUser(user);
    commit("system/setLoading", false, { root: true });
    commit("system/setInfo", response.data, { root: true });
  },
  updatePersonal({ commit }, { currentPassword, newPassword, newEmail }) {
    return new Promise((res, rej) => {
      commit("system/setLoading", true, {
        root: true
      });

      reauthenticate(currentPassword)
        .then(() => {
          var user = auth.currentUser;
          console.log(user)
          if(newEmail!= user.email)
          user
            .updateEmail(newEmail)
            .then(() => {
              res('ok')
              console.log("Email updated!");
            })
            .catch(error => {
              rej(error)
            });
          if(newPassword.length>0&&newPassword != currentPassword)
            user
              .updatePassword(newPassword)
              .then(() => {
                console.log("Password updated!");
                res('ok')
                commit("system/setInformation", "Пароль успешно обновлён", {
                  root: true
                }).catch(error => {
                  console.log(error)
                });
           
            commit("system/setLoading", false, {
              root: true
            });
            })
            .catch(error => {
              console.log(error)
              rej(error)
            });
        })
        .catch(error => {
          rej(error)
        });
    });
  },
  changeEmail({ commit }, { currentPassword, newEmail }) {
     commit("system/setLoading", true, {
      root: true
    });
    reauthenticate(currentPassword)
      .then(() => {
        var user = auth.currentUser;
        user
          .updateEmail(newEmail)
          .then(() => {
             commit("system/setInfo", 'Ваш e-mail обновлён!', {
              root: true
            });
            console.log("Email updated!");
             commit("system/setLoading", false, {
              root: true
            });
          })
          .catch(error => {
            commit("system/setError", error, {
              root: true
            });
            console.log(error);
          });
      })
      .catch(error => {
        commit("system/setError",error, {
              root: true
            });
        console.log(error);
      });
  },
  updateProfile({ commit }, user) {
    commit("system/setLoading", true, {
      root: true
    });
    let updates = [];
    
    let updateUserCore = auth.currentUser
      .updateEmail(user.email)
      .then(() => {
        console.log("Email updated!");
      })
      .catch(error => {
        console.log(error);
      });
    

    let updateUserAdditional = usersCollection
      .doc(auth.currentUser.uid)
      .update(user);
    if(auth.currentUser.email!=user.email)
      updates.push(updateUserCore)
    updates.push(updateUserAdditional)
    return Promise.all(updates)
      .then(() => {
        Promise.resolve("Профиль успешно обновлён");
        
        commit("system/setLoading", false, {
          root: true
        });
        commit("system/setInfo", "Профиль успешно обновлён", {
          root: true
        });
      })
      .catch(err => {
        commit("system/setLoading", false, {
          root: true
        });
        Promise.reject(err);
      });
  },
  resetPassword({ commit }, payload) {
    commit("system/setLoading", true, {
      root: true
    });
    commit("system/clearError", null, {
      root: true
    });
    auth.languageCode = "ru";
    auth
      .sendPasswordResetEmail(payload)
      .then(function() {
        // Email sent.
        commit("system/setLoading", false, {
          root: true
        });
        commit("system/setInfo", "Проверьте вашу почту для сброса пароля", {
          root: true
        });
      })
      .catch(function(error) {
        // An error happened.
        commit("system/setError", error.message, {
          root: true
        });
      });
  },
  verifyEmail({ commit }, payload) {
    commit("system/setLoading", true, {
      root: true
    });
    let ID = payload.id;
    delete payload.id;
    payload.verified = true;
    usersCollection
      .doc(ID)
      .update(payload)
      .then(() => {
        commit("system/setLoading", false, {
          root: true
        });
        commit("system/setInfo", "Подтверждение отправлено", {
          root: true
        });
      });
  },
  fetchUserProfile({ commit,dispatch }, user) {
    return new Promise((resolve, reject) => {
      console.log("HERE")
       commit("system/changeModal", {show:false}, {
          root: true
        });
      commit("setCurrentUser", user);
      dispatch('bindUserProfile',user.uid)
      .then(() =>{
        resolve();
        usersCollection.doc(user.uid).set({ lastLogin: new Date() },{merged:true});
      }).catch(err => reject(err))
      // usersCollection
      //   .doc(user.uid)
      //   .get()
      //   .then(res => {
      //     var data = res.data();
      //     commit("setUserProfile", data);
      //     resolve(data);
      //     usersCollection.doc(user.uid).set({ lastLogin: new Date() },{merged:true});
      //   })
      //   .catch(err => {
      //     if (err.code === "unavailable")
      //       commit(
      //         "system/setError",
      //         "Возникли проблемы со входом в систему. Конфликт с Вашей антивирусной программой. Пожалуйста, обратитесь в чат поддержки и мы быстро решим вопрос!",
      //         {
      //           root: true
      //         }
      //       );

      //     reject(err);
      //   });
    });
  },
  async signUserUp({ commit }, payload) {
    
    commit("system/setLoading", true, {
      root: true
    });
    commit("system/clearError", null, {
      root: true
    });
    try{
        const user = await auth.createUserWithEmailAndPassword(payload.email, payload.pass)
        delete payload["pass"];
        delete payload["attach"];
        delete payload["confirmPassword"];
        if(user){
          await usersCollection.doc(user.user.uid).set({...payload,createdOn: new Date()})
          await api.user.attach()
          await api.user.replaceToken(localStorage.getItem('anonymUser'))
          localStorage.removeItem('anonymUser')
          commit("system/setLoading", false, { root: true });
          commit("system/changeModal", {show:false}, {root: true});
          commit("system/setInfo", 'Теперь вы можете приступить к работе.', { root: true });
          Promise.resolve(true);
        }
      } 
    catch(error)  {
        commit("system/setLoading", false, {
          root: true
        });
        commit("system/setError", lang[error.code], {
          root: true
        });
        Promise.reject(lang[error.code]);
    }
  },
  signUserOut({ dispatch, commit }) {
    new Promise((res, rej) => {
      commit("system/setLoading", true, {
        root: true
      });
      auth.signOut().then(
        function() {
          // Sign-out successful.
          commit("system/setLoading", false, {
            root: true
          });
          dispatch("system/clearData", null, {
            root: true
          });
          location.reload();
          res("logged out");
        },
        function(error) {
          commit("system/setError", error, {
            root: true
          });
          rej("can't logout");
          // An error happened.
        }
      );
    });
  },
 async signUserIn({ commit}, payload) {
    auth.languageCode = "ru";
    commit("system/setLoading", true, {
      root: true
    });
   return  await auth
      .signInWithEmailAndPassword(payload.email, payload.pass)
      .then(() => {
      if(localStorage.getItem('anonymUser'))
        api.user.replaceToken(localStorage.getItem('anonymUser')).then((res)=>{
          console.log(res)
          localStorage.removeItem('anonymUser')
        })
        commit("system/changeModal", {show:false}, {
          root: true
        });
        commit("system/setLoading", false, {
          root: true
        });
        commit("system/clearError", null, {
          root: true
        });
        Promise.resolve();
      })
      .catch(error => {
        console.error(error)
        Promise.reject(error.message);
        commit("system/setLoading", false, {
          root: true
        });
        commit("system/setError", lang[error.code], {
          root: true
        });
      });
  }
};

const mutations = {
  ...vuexfireMutations,
  setUserParent(state, val) {
    state.userProfile.parentEmail = val;
  },
  setAwaitingStatus(state,url){
    state.userProfile.attachment = url;
    state.userProfile.status = 'requested'
  },
  setCurrentUser(state, val) {
    state.currentUser = val;
  },
  setBalance(state, val) {
    state.balance = val;
  },
  setUnverifiedStatus(state){
    state.userProfile.status = 'unverified'
  },
  setUserProfile(state, val) {
    state.userProfile = val;
  }
};
export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
