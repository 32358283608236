

const state = {
    loading: false,
    modalLogin:false,
    error: null,
    info : null
}

const getters = {
    loading (state) {
        return state.loading
      }, 
  isLoginModal (state){
      return state.modalLogin
  },
      error (state) {
        return state.error
      },
      info (state) {
        return state.info
      }
}

const actions = {
    clearData ({ commit }) {
        commit('user/setCurrentUser', null, {root : true})
        commit('user/setUserProfile', {}, {root : true})
        // commit('operations/setPosts', null , {root : true} )
        // commit('operations/setRequests', null , {root : true} )
        // commit('operations/setHiddenPosts', null , {root : true} )
      },
    setLoad ({commit}, status) {
        commit('setLoading', status)
      },
    showModal({commit}, status){
      commit('changeModal', status)
    },
      setError ({commit}, error) {
        commit('setError', error)
      },
    setInformation ({commit}, status) {
      commit('setInfo', status)
    },
    clearError ({commit}) {
        commit('clearError')
    }
}


const mutations = {
    changeModal(state,payload){
        state.modalLogin = payload
    },
    setLoading (state, payload) {
        state.loading = payload
      },
      setError (state, payload) {
        state.error = payload
      },
      setInfo (state, payload){
        state.info = payload
      },
      clearError (state) {
        state.error = null
      },

}
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }
